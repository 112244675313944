import React from 'react'
import { Link } from 'gatsby';
import c from 'classnames';
import SVG from 'react-inlinesvg';
import { withState } from 'recompose';
import styled from 'styled-components';

import Layout from 'components/layout';
import helpers from 'styles/_helpers.module.scss';
import wrappers from 'styles/_wrappers.module.scss';
import styles from 'styles/pages/parceiros.module.scss';
import logoFirst from 'assets/images/logo_emporio.png';
import logoSecond from 'assets/images/logo_tirant.png';
import logoThird from 'assets/images/logo_caace.png';
import logoFourth from 'assets/images/logo_adpec.png';
import logoFifth from 'assets/images/logo_folhadirigida.png';

const Shadow = styled.div`
  box-shadow: 2px 10px 30px rgba(217, 217, 217, 0.5);
  border-radius: 0.8rem;
  overflow: hidden;
`;

const Parceiros = props => (
  <Layout location={props.location}>
    <div className={c(styles.hero)}>
      <div className={styles.text_wrapper}>
        <div className={c(styles.card)}>
          <h2>Simples. Prático. Completo.</h2>
          <h1 className={styles.big_title}>nossos parceiros</h1>
          <h1 className={styles.entry_title}>
           {String.fromCharCode(160)} Nossos parceiros são instituições ligadas ao  <br/> {String.fromCharCode(160)} mundo do direito brasileiro de várias áreas.{String.fromCharCode(160)}
          </h1>
        </div>
      </div>
    </div>
    <div className={styles.logos_wrapper}>
      <a href="https://emporiododireito.com.br">
        <img src={logoFirst} alt="" className={styles.first}/>
      </a>
      <a href="https://www.tirant.com/br">
        <img src={logoSecond} alt=""/>
      </a>
      <a href="http://caace.org.br">
        <img src={logoThird} alt=""/>
      </a>
      <a href="http://www.adpec.org.br/">
        <img src={logoFourth} alt=""/>
      </a>
      <a href="https://folhadirigida.com.br/">
        <img src={logoFifth} alt=""/>
      </a>
    </div>
    <div className={styles.pareceria_wrapper}>
      <div className={styles.text_wrapper}>
        <h1>Quer ser nosso parceiro ?</h1>
        <p>
          Os nossos parceiros procuram vantagens nos produtos Direito Anotado para distribuição em larga escala. <br/>
          Quer oferecer um produto Direito Anotado aos seus colaboradores? <br/>
          Precisa de um desconto especial para educação? <br/>
        </p>
        <div className={styles.contact_wrapper}>
          <h2>As nossas soluções são <br/>customizadas às suas necessidades.</h2>
          <a href="mailto:cpc.anotado@direitoanotado.com.br" className={c(helpers.mt24,styles.btn)}>Contacte-nos</a>
        </div>
      </div>
    </div>
  </Layout>
);

const enhance = withState('isVideoOpened', 'setVideoOpened', false);
export default enhance(Parceiros);
// export default Landing;
